<template>
  <b-form
    class="bg-white p-2"
    @submit.prevent
  >

    <h3 class="mb-2">
      Warunki umowy
    </h3>
    <b-row>

      <b-col cols="12">
        <b-form-group
          label="Przedmiot umowy"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Przedmiot umowy"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Miejsce pracy"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Miejsce pracy"
          />
        </b-form-group>
      </b-col>

      <!-- etat  -->
      <b-col cols="12">
        <b-form-group
          label="Etat"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="etat"
          />
        </b-form-group>
      </b-col>

      <!-- Kwota kosztów uzyskania przychodów  -->
      <b-col cols="12">
        <b-form-group
          label="Kwota kosztów uzyskania przychodów"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="kup"
          />
        </b-form-group>
      </b-col>

      <!-- Rozliczany procent kwoty wolnej od podatku -->
      <b-col cols="12">
        <b-form-group
          label="Rozliczany procent kwoty wolnej od podatku"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="percent"
          />
        </b-form-group>
      </b-col>

      <!-- Naliczanie składki na Fundusz Pracy -->
      <b-col cols="12">
        <b-form-group
          label="Naliczanie składki na Fundusz Pracy"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="fun"
          />
        </b-form-group>
      </b-col>

      <!-- Składka na Fundusz Emerytur Pomostowych-->
      <b-col cols="12">
        <b-form-group
          label="Składka na Fundusz Emerytur Pomostowych"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="funPom"
          />
        </b-form-group>
      </b-col>

      <!-- Okres umowy -->
      <b-col cols="12">
        <b-form-group
          label="Okres umowy"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="period"
          />
        </b-form-group>
      </b-col>

      <!-- Data podpisania -->
      <b-col cols="12">
        <b-form-group
          label="Data podpisania"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Data rozpoczęcia -->
      <b-col cols="12">
        <b-form-group
          label="Data rozpoczęcia"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Data zakończenia -->
      <b-col cols="12">
        <b-form-group
          label="Data zakończenia"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Kwota -->
      <b-col cols="12">
        <b-form-group
          label="Kwota (brutto)"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Kwota brutto"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label=""
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Dodaj umowę
          </b-button>
        </b-form-group>
      </b-col>

    </b-row>

  </b-form>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      etat: [{ title: 'pół etatu 1/2' }, { title: 'cały etat / 1' }, { title: '3/4 etatu' }],
      kup: [{ title: 'Zwykła' }, { title: 'Podwyższona' }, { title: 'Brak' }],
      percent: [{ title: '0%' }, { title: '100%' }, { title: 'Brak' }],
      fun: [{ title: 'Nigdy' }, { title: 'Tak' }, { title: 'w zależności od wynagrodzenia' }],
      funPom: [{ title: 'Nie' }, { title: 'Tak' }],
      period: [{ title: 'Próbny' }, { title: 'Nieokreślony' }, { title: 'Określony' }],
      dateDefault: null,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
